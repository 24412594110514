.c-animation {
    visibility: visible;
    animation-duration: 2500ms;
    animation-delay: 0ms;
    animation-name: zoomInStable;
}

.c-theme-btn {
    width: 100%;
}
